<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="-1"
    :loading="isLoading"
  >
    <template v-slot:item.attributes.name="{ item }">
      <div class="py-5">{{ item.attributes.name | titleCase }}</div>
    </template>

    <template v-slot:item.status="{ item }">
      <v-chip small light text-color="grey darken-2">
        {{ item.attributes.status | titleCase }}</v-chip
      >
    </template>

    <template v-slot:item.attributes.itemUnitsResponseCount="{ item }">
      {{ item.attributes.itemUnitsResponseCount }} ({{
        item.attributes.customAttributes.packQuantity || '1'
      }})
    </template>

    <template v-slot:item.totalVolume="{ item }">
      {{
        item.attributes.volume *
          item.attributes.itemUnitsResponseCount *
          (item.attributes.customAttributes.packQuantity || 1)
      }}
      {{ item.attributes.volume && item.attributes.volumeUnit }}
    </template>

    <template v-slot:item.dimensions="{ item }">
      <DimensionsTableField :item="item" />
    </template>

    <template v-slot:item.supplier>
      Tonewoods S.L
    </template>

    <template v-slot:item.specie="{ item }">
      {{ $t(`species.${item.attributes.customAttributes.specie.text}`) }}
    </template>
    <template v-slot:top>
      <v-dialog v-model="dialog" max-width="500px">
        <v-card>
          <v-card-title>
            Edit Quantity
          </v-card-title>

          <v-card-text>
            <v-container>
              <QuantityField
                :key="key"
                :maximum="true"
                :item="editedItem"
                filled
              />
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              depressed
              color="grey lighten-1"
              light
              outlined
              @click="dialog = false"
              >{{ $t('actions.cancel') }}</v-btn
            >
            <v-btn depressed color="primary" @click="onSubmit">{{
              $t('actions.save')
            }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>

    <template v-slot:item.actions="{ item }">
      <v-btn
        icon
        small
        color="blue accent-1"
        @click="
          $router.push({
            name: 'inventory-item-show',
            params: { inventoryItemId: item.id }
          })
        "
      >
        <v-icon small>mdi-eye</v-icon>
      </v-btn>
      <v-btn
        icon
        :disabled="!isAdmin"
        small
        color="blue accent-2"
        @click="editItem(item)"
      >
        <v-icon small>mdi-counter</v-icon>
      </v-btn>
      <v-btn
        :disabled="!isAdmin"
        icon
        small
        color="amber accent-2"
        @click="
          $router.push({
            name: 'inventory-item-edit',
            params: { inventoryItemId: item.id }
          })
        "
      >
        <v-icon small>mdi-pencil-outline</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { ORGANIZATION_ID } from '@/common/config'
import QuantityField from '@/components/QuantityField'
import DimensionsTableField from '@/components/DimensionsTableField'
import { mapGetters } from 'vuex'
export default {
  components: {
    QuantityField,
    DimensionsTableField
  },
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      key: 0,
      dialog: false,
      editedItem: null,
      isLoading: false,
      clientId: null
    }
  },

  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      items: 'itemUnit/items',
      order: 'saleOrder/order'
    }),
    headers() {
      return this.createTableHeaders()
    }
  },
  async created() {
    this.fetchData()
  },

  methods: {
    async fetchData() {
      this.isLoading = true
      await this.filterUnits()
      this.isLoading = false
    },
    async filterUnits() {
      const params = {
        // organizationId: ORGANIZATION_ID(),
        orderId: this.orderId
      }
      return await this.$store.dispatch('itemUnit/filter', {
        params,
        attribute: '/units-on-order'
      })
    },
    async editItem(item) {
      this.key = item.id
      this.editedIndex = this.items.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.editedItem.attributes.quantity =
        item.attributes.itemUnitsResponseCount
      this.clientId = await this.fetchClientId()
      this.dialog = true
    },
    async fetchClientId() {
      const response = await this.$store.dispatch('party/filter', {
        orderIds: this.orderId
      })
      if (response.length) return response[0].attributes.userId
    },
    async onSubmit() {
      const {
        id,
        attributes: { quantity }
      } = this.editedItem

      const itemUnit = {
        id,
        attributes: {
          quantity,
          clientId: this.clientId,
          orderId: this.order.id,
          organizationId: ORGANIZATION_ID()
        }
      }
      await this.createItemUnit(itemUnit)
      this.fetchData()
      this.dialog = false
    },
    async createItemUnit(itemUnit) {
      await this.$store.dispatch('itemUnit/update', {
        data: itemUnit,
        attribute: 'units-on-order'
      })
    },
    createTableHeaders() {
      return [
        {
          text: this.$t('product.description'),
          value: 'attributes.description',
          sortable: false
        },
        {
          text: this.$tc('product.part', 1) + '#',
          align: 'start',
          sortable: false,
          value: 'attributes.name'
        },

        {
          text: this.$tc('item.unit', 2),
          value: 'attributes.itemUnitsResponseCount',
          sortable: false
        },
        {
          text: this.$t('item.totalVolume'),
          align: 'start',
          value: 'totalVolume',
          sortable: false
        },
        {
          text: this.$t('item.dimensions'),
          value: 'dimensions',
          sortable: false
        },
        {
          text: this.$tc('user.supplier', 1),
          value: 'supplier',
          sortable: false
        },
        {
          text: this.$t('item.specie'),
          value: 'specie',
          sortable: false
        },
        {
          text: this.$t('item.category'),
          value: 'attributes.customAttributes.category',
          sortable: false
        },
        {
          text: this.$t('item.type'),
          value: 'attributes.customAttributes.type',
          sortable: false,
          width: 100
        },
        {
          text: this.$t('item.ncCode'),
          value: 'attributes.customAttributes.ncCode',
          sortable: false
        },

        {
          text: '',
          value: 'actions',
          sortable: false,
          align: 'end'
        }
      ]
    }
  }
}
</script>

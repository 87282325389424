<template>
  <v-btn
    depressed
    tile
    class="mx-2"
    color="success"
    :disabled="disabled"
    :loading="isLoading"
    @click="onClick"
  >
    <v-icon left>{{
      order && order.attributes.status !== 'pending'
        ? 'mdi-check'
        : 'mdi-send-outline'
    }}</v-icon>
    {{
      order && order.attributes.status !== 'pending'
        ? $t('actions.confirmed')
        : $t('actions.confirmShipment')
    }}
  </v-btn>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      order: 'saleOrder/order',
      isAdmin: 'auth/isAdmin'
    }),
    disabled() {
      return (
        !this.order?.id ||
        this.order?.attributes.status !== 'pending' ||
        !this.isAdmin
      )
    }
  },
  methods: {
    async onClick() {
      this.isLoading = true
      const hash = await this.createTransactionHash()
      await this.updateSaleOrder(hash)
      this.isLoading = false
    },
    async createTransactionHash() {
      const response = await this.$store.dispatch(
        'blockchain/create',
        this.order
      )
      return response.result
    },
    async updateSaleOrder(hash) {
      const order = { ...this.order }
      order.attributes.status = 'confirmed'
      order.attributes.customAttributes.blockchain = hash
      await this.$store.dispatch('saleOrder/update', order)
    }
  }
}
</script>

<style lang="scss" scoped></style>

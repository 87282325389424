<template>
  <v-card flat tile
    ><v-card-title class="text-h6">{{ $t('common.details') }}</v-card-title>
    <v-list
      ><v-list-item v-if="meta">
        <v-list-item-icon> <v-icon small>mdi-receipt</v-icon></v-list-item-icon>
        <v-list-item-content>{{
          $t('sale.invoiceNumber')
        }}</v-list-item-content>
        <v-list-item-content class="font-weight-bold">{{
          meta.invoiceNumber || '-'
        }}</v-list-item-content></v-list-item
      >
      <SaleOrderShowMetaBlockchain />
    </v-list>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
import SaleOrderShowMetaBlockchain from './SaleOrderShowMetaBlockchain'
export default {
  components: {
    SaleOrderShowMetaBlockchain
  },
  computed: {
    ...mapGetters({
      order: 'saleOrder/order'
    }),
    meta() {
      return this.order.attributes.customAttributes
    }
  }
}
</script>

<template>
  <v-container v-if="order" fluid>
    <SaleOrderShowHeader />
    <!-- Alberto orgId 4e554e3e-8897-4159-b729-9b7cad8939e8 -->
    <CloneItemsAlert
      v-if="
        !isAdmin ||
          (order.attributes.clientOrganizationId &&
            $route.path.includes('purchase-order'))
      "
      :order-id="orderId"
    />
    <SaleOrderShowMeta />
    <BillOfLadingDetails />
    <OriginDestination />

    <PartyIndex
      class="my-4"
      :order-id="orderId"
      :forest-manager-ids="order.attributes.customAttributes.forestManagerIds"
    />

    <ItemSalesQuotationIndex class="my-4" :order-id="order.id" />

    <ItemUnitsOnOrderIndex class="my-4" :order-id="order.id" />
    <DocumentIndex class="my-4" :resource-id="orderId" resource-type="Order" />
    <base-scroll-up-button class="my-4" />
  </v-container>
</template>

<script>
import SaleOrderShowHeader from './components/SaleOrderShowHeader'
import SaleOrderShowMeta from './components/SaleOrderShowMeta'
// import ItemProductIndexTable from '@/views/item-product/components/ItemProductIndexTable'
import OriginDestination from '@/views/load/components/OriginDestination'
import DocumentIndex from '@/views/document/components/DocumentIndex'
import PartyIndex from '@/views/party/components/PartyIndex'
import BillOfLadingDetails from '@/views/sale-order/components/BillOfLadingDetails.vue'
import ItemSalesQuotationIndex from '@/views/item-sales-quotation/components/ItemSalesQuotationIndex'
import ItemUnitsOnOrderIndex from '@/views/item-units-on-order/components/ItemUnitsOnOrderIndex'
import CloneItemsAlert from '@/views/sale-order/components/CloneItemsAlert'
import { mapGetters } from 'vuex'
export default {
  components: {
    SaleOrderShowHeader,
    SaleOrderShowMeta,
    BillOfLadingDetails,
    OriginDestination,
    DocumentIndex,
    PartyIndex,
    ItemSalesQuotationIndex,
    ItemUnitsOnOrderIndex,
    CloneItemsAlert
  },

  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      order: null
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      organizationId: 'auth/organizationId'
    })
  },

  async created() {
    const orderIds = this.orderId
    this.order = await this.$store.dispatch('saleOrder/fetch', orderIds)
    await this.$store.dispatch('load/filter', { orderIds })
  }
}
</script>

<template>
  <base-secondary-table-layout :card-title="$tc('product.deliveredPart', 2)">
    <template v-slot:button>
      <base-new-outlined-button
        v-if="isAdmin"
        :to="{
          name,
          params: { orderId }
        }"
        >{{ $tc('product.part', 1) }}</base-new-outlined-button
      >
    </template>
    <ItemUnitsOnOrderIndexTable :order-id="orderId" />
  </base-secondary-table-layout>
</template>

<script>
import ItemUnitsOnOrderIndexTable from './ItemUnitsOnOrderIndexTable'
import { mapGetters } from 'vuex'
export default {
  components: {
    ItemUnitsOnOrderIndexTable
  },
  props: {
    orderId: { type: String, required: true }
  },
  data() {
    return {
      name: 'item-units-on-order-new'
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin'
    })
  }
}
</script>

<template>
  <div>
    <BaseLayoutTitle
      :prefix="
        order.attributes.clientOrganizationId !== organizationId
          ? $t('sale.shortName')
          : $t('purchase.shortName')
      "
      :title="reference"
    >
      <template v-slot:edit>
        <v-btn
          v-if="isAdmin"
          :to="{ path: 'edit' }"
          append
          color="primary"
          outlined
          tile
          ><v-icon left>mdi-clipboard-edit-outline</v-icon
          >{{ $t('actions.edit') }}</v-btn
        ></template
      >
      <template v-slot:selector>
        <StatusSelector
          class="ml-10"
          :status="order.attributes.status"
          :options="options"
          @change="updateStatus"
      /></template>
      <template v-slot:btn>
        <SaleOrderConfirmationButton v-if="actions"
      /></template>
    </BaseLayoutTitle>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import StatusSelector from '@/components/StatusSelector'
import SaleOrderConfirmationButton from '@/views/sale-order/components/SaleOrderConfirmationButton'
export default {
  name: 'SaleOrderShowHeader',
  components: {
    StatusSelector,
    SaleOrderConfirmationButton
  },
  props: {
    actions: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      options: [
        {
          text: this.$t('orderStatus.pending'),
          status: 'pending',
          color: 'grey lighten-5'
        },
        {
          text: this.$t('orderStatus.confirmed'),
          status: 'confirmed',
          color: 'amber lighten-5'
        },
        {
          text: this.$t('orderStatus.in_process'),
          status: 'in_process',
          color: 'blue lighten-5'
        },
        {
          text: this.$t('orderStatus.dispatched'),
          status: 'dispatched',
          color: 'green lighten-5'
        },
        {
          text: this.$t('orderStatus.closed'),
          status: 'closed',
          color: 'red lighten-5'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      isAdmin: 'auth/isAdmin',
      organizationId: 'auth/organizationId',
      order: 'saleOrder/order',
      orderId: 'saleOrder/orderId'
    }),
    reference() {
      const { name } = this.order.attributes.customAttributes
      return name
    },
    invoiceNumber() {
      const { invoiceNumber } = this.order.attributes.customAttributes
      return invoiceNumber
    }
  },
  methods: {
    ...mapActions({ updateSaleOrder: 'saleOrder/update' }),
    async updateStatus({ status }) {
      const order = {
        ...this.order,
        ...{ attributes: { status } }
      }
      return await this.updateSaleOrder(order)
    }
  }
}
</script>

<template>
  <div>
    <v-alert v-model="alert" type="info" text top prominent>
      {{ $t('alerts.itemsNotDelivered') }}
      <!-- <template v-slot:action>
        <v-btn text @click="onSubmit">{{
          $t('actions.confirm')
        }}</v-btn></template
      > -->
    </v-alert>
    <v-alert v-if="!dialog" v-model="snackbar" type="success" text>
      <v-row align="center">
        <v-col class="grow"> {{ $t('alerts.cloneItemsAlert') }}</v-col>
        <v-col class="shrink">
          <v-btn depressed color="success" @click="onSubmit">{{
            $t('actions.confirm')
          }}</v-btn></v-col
        >
      </v-row>
    </v-alert>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <v-icon left>mdi-content-copy</v-icon> {{ $t('actions.clone') }}
          {{ $tc('item.name', 2) }}
        </v-card-title>
        <v-card-text>
          {{ $t('alerts.cloneItemsAlert') }}
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :loading="isLoading" color="primary" text @click="onSubmit"
            ><v-icon left>mdi-check-circle</v-icon
            >{{ $t('actions.confirm') }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  props: {
    orderId: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      dialog: false,
      alert: false,
      snackbar: false,
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({ isAdmin: 'auth/isAdmin' })
  },
  created() {
    this.checkItemsAreDelivered()
    this.checkItemsAreCloned()
  },
  methods: {
    async checkItemsAreDelivered() {
      const items = await this.$store.dispatch('itemUnit/filter', {
        params: { orderId: this.orderId },
        attribute: '/units-on-order'
      })
      this.alert = !items.length
    },
    async checkItemsAreCloned() {
      const items = await this.filterItems({
        orderId: this.orderId,
        category: 'client_end_product,resource'
      })
      if (this.alert) return
      this.dialog = !items.length
      this.snackbar = this.dialog
    },
    async onSubmit() {
      this.isLoading = true
      const data = {
        type: 'Item',
        attributes: {
          orderId: this.orderId,
          category: this.isAdmin ? 'resource' : 'client_end_product'
        }
      }
      await this.cloneItems(data)
      this.isLoading = false
      this.dialog = false
      this.snackbar = false
    },
    ...mapActions({
      cloneItems: 'inventoryItem/clone',
      filterItems: 'inventoryItem/filter'
    })
  }
}
</script>

<style lang="scss" scoped></style>

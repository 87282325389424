<template>
  <v-list-item
    v-if="blockchain"
    :href="`https://rinkeby.etherscan.io/tx/${blockchain}`"
    target="_blank"
    class="grey lighten-5"
  >
    <v-list-item-icon> <v-icon small>mdi-link-lock</v-icon></v-list-item-icon>
    <v-list-item-content>{{
      $t('blockchain.registration')
    }}</v-list-item-content>
    <v-list-item-content
      class="font-weight-bold text-body-2 purple--text text--lighten-1"
    >
      <span class="d-inline-block text-truncate" style="max-width: 600px;">
        {{ blockchain || '-' }}
      </span></v-list-item-content
    ></v-list-item
  >
</template>

<script>
export default {
  computed: {
    blockchain() {
      const order = this.$store.getters['saleOrder/order']
      const { blockchain } = order?.attributes.customAttributes
      return blockchain
    }
  }
}
</script>
